@import 'modules/variables'
@import "config"

body,
html,
main,
#__next
	width: 100%
	//height: 100%

html
	min-height: 100%

body
	min-height: 100vh
	text-rendering: optimizeSpeed


img,
picture
	max-width: 100%
	display: block

a
	text-decoration: none
	color: inherit

a:not([class])
	text-decoration-skip-ink: auto

@media (prefers-reduced-motion: reduce)
	*,
	*::before,
	*::after
		animation-duration: 0.01ms !important
		animation-iteration-count: 1 !important
		transition-duration: 0.01ms !important
		scroll-behavior: auto !important


*:focus
	outline: none


p,
dd,
dl,
figure,
blockquote
	margin: 0


blockquote,
q
	quotes: none


ul,
ol
	padding: 0
	margin: 0
	list-style-type: none


table
	border-collapse: collapse
	border-spacing: 0


th
	font-weight: inherit


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, main, menu, nav, output, ruby, section, summary, time, mark, audio, video
	line-height: inherit
	text-align: inherit
	vertical-align: inherit
	margin: 0
	font-size: inherit
	font-weight: inherit


audio,
video
	display: block


img
	display: block
	border: none


iframe
	border: none


pre,
code,
kbd,
samp
	font-family: monospace, monospace
	font-size: inherit


a
	background-color: transparent
	text-decoration: none
	color: inherit


abbr
	border: none
	text-decoration: none


b,
strong
	font-weight: inherit


i,
em
	font-style: inherit


dfn
	font-style: inherit


mark
	background-color: transparent
	color: inherit


small
	font-size: inherit


sub,
sup
	position: relative
	vertical-align: baseline
	font-size: inherit
	line-height: 0


sub
	bottom: -.25em


sup
	top: -.5em


button,
input,
optgroup,
select,
textarea
	padding: 0
	margin: 0
	border: none
	border-radius: 0
	box-shadow: none
	background-color: transparent
	font: inherit
	color: inherit
	letter-spacing: inherit


button,
input
	overflow: visible


button,
select
	text-align: left
	text-transform: none


button,
[type='button'],
[type='reset'],
[type='submit']
	cursor: pointer
	-webkit-appearance: none


textarea
	resize: none
	overflow-y: auto
	overflow-x: hidden


button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner
	border: none
	padding: 0


button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring
	outline: none


[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button
	height: auto


[type='search']
	outline: none


[type='search']::-webkit-search-decoration
	-webkit-appearance: none


::-webkit-file-upload-button
	-webkit-appearance: none
	font: inherit


fieldset
	padding: 0
	margin: 0
	border: none


legend
	display: block
	padding: 0
	white-space: normal


select
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none


select::-ms-expand
	display: none


::-webkit-input-placeholder
	color: inherit
	opacity: 1
	transition: opacity .3s


input::-moz-input-placeholder
	color: inherit
	opacity: 1
	transition: opacity .3s


input:-moz-placeholder
	color: inherit
	opacity: 1
	transition: opacity .3s


input:-ms-input-placeholder
	color: inherit
	opacity: 1
	transition: opacity .3s


input:focus::-webkit-input-placeholder
	opacity: 0

input:focus::-moz-input-placeholder
	opacity: 0


input:focus:-moz-placeholder
	opacity: 0


input:focus:-ms-input-placeholder
	opacity: 0


svg
	display: block
	width: 100%
	height: 100%
	fill: currentColor


[hidden]
	display: none


.disabled,
::disabled
	cursor: not-allowed


::-ms-clear
	display: none


input:-webkit-autofill
	box-shadow: 0 0 100px #fff inset
	-webkit-text-fill-color: currentColor


::selection
	color: #fff
	background-color: #004fe4

