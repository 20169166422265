@import 'modules/variables'
@import "config"


*
    outline: none
    line-height: inherit
    text-align: inherit
    vertical-align: inherit


*, *::before, *::after
    box-sizing: border-box


html
    box-sizing: border-box
    height: 100%
    -ms-text-size-adjust: 100%
    -webkit-text-size-adjust: 100%


body
    overflow-y: auto
    display: block
    text-rendering: optimizeSpeed
    height: 100%
    min-width: $min-width
    background-color: #fff
    color: $color
    font: 400 16px/150% $font
    position: relative
    text-decoration-skip: objects
    -moz-text-size-adjust: auto
    -webkit-text-size-adjust: auto
    -ms-text-size-adjust: auto
    text-size-adjust: auto
    -webkit-font-smoothing: antialiased
    -webkit-tap-highlight-color: transparent
    +max-width($tablet)
        font: 400 14px/150% $font

main
    min-height: 100%
    overflow: hidden


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    html
        display: flex
        flex-direction: column


.clearfix:after
    content: ''
    display: block
    clear: both


.visually-hidden
    position: absolute
    z-index: -1
    width: 0
    height: 0
    padding: 0
    margin: 0
    border: none
    overflow: hidden


.cover-pic,
.contain-pic
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%


.cover-pic
    object-fit: cover


.contain-pic
    object-fit: contain


.footer-page
    margin-top: auto

// Для блока "Контент"
// ===================
.wrapper
    margin: 0 auto
    width: 100%
    position: relative
    display: flex
    min-height: 100%
    overflow: hidden

.wrapper-inner
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between

.middle
    position: relative

    &--open-map
        height: 819px
        +max-width($xl-desktop)
            height: 90vh

.content
    margin: 0 auto
    position: relative
    min-height: 100%
    background: transparent
    width: 100%
    padding: 0

// кастомизация выделения текста на сайте
// ======================================
::selection
    background: $color
    color: #fff !important

::-moz-selection
    background: $color
    color: #fff !important

::-webkit-selection
    background: $color
    color: #fff !important

a::selection
    background: $color
    color: $link !important

a::-moz-selection
    background: $color
    color: $link !important

a::-webkit-selection
    background: $color
    color: $link !important

// Описание базовых стилей для элементов
// =====================================
a, input, button, textarea, *:before, *:after, *:hover, *:active, *:focus
    outline: none

a
    color: $link
    text-decoration: none
    transition: color 0.3s ease, opacity 0.3s ease, border 0.3s ease

    &:hover, &:active, &:focus
        color: $hover
        text-decoration: none

p
    margin: 0 0 10px 0

.hidden
    display: none

img
    max-width: 100%
    vertical-align: bottom

.center
    text-align: center

p, li
    a
        transition: color .3s ease

// Оформление списков
ul
    position: relative
    display: block
    width: 100%
    margin: 15px 0
    padding: 0
    list-style: none

    li
        position: relative
        margin: 0 0 10px 0

        p
            margin: 0


        p + p
            margin: 0 0 10px 0


// Ненумерованный список
ul
    li
        padding: 0 0 0 20px

        &:before
            content: ''
            position: absolute
            width: 8px
            height: 8px
            border-radius: 50%
            background: #7927E0
            overflow: hidden
            top: 8px
            left: 0


// Нумерованный список
ol
    counter-reset: li

    li
        padding: 0 0 0 25px

        &:before
            counter-increment: li
            content: counters(li, ".") ". "
            position: absolute
            width: 20px
            text-align: right
            left: 0
            color: #2a2d33


.red
    color: $red

// Запрет на "hover" при скроллинге страницы
// =========================================
.disable-hover, .disable-hover *
    pointer-events: none !important

// ========
// WRAPPERS
// ========

.full-wrapper
    +full-wrapper
    width: 100%

.hide
    display: none

.show
    display: block

// убираю лишние отступы в мобильной версии

br
    display: block

br[class='desktop-br']
    display: block
    +max-width($lg-tablet)
        display: none

br[class='mobail-br']
    display: none
    +max-width($lg-tablet)
        display: block

@keyframes animTop
    0%
        transform: translateY(-50px)
        opacity: 0

    100%
        transform: translateY(0)
        opacity: 1

@keyframes animBot
    0%
        transform: translateY(50px)
        opacity: 0

    100%
        transform: translateY(0)
        opacity: 1

@keyframes animOpacity
    0%
        opacity: 0

    100%
        opacity: 1

@keyframes animTranslateY
    0%
        opacity: 0
        transform: translateY(30px)

    100%
        opacity: 1
        transform: translateY(0)

@keyframes animTranslateX
    0%
        opacity: 0
        transform: translateX(30px)

    100%
        opacity: 1
        transform: translateX(0)

@keyframes animScale
    0%
        opacity: 0
        transform: scale(.7)

    100%
        opacity: 1
        transform: scale(1)

@keyframes animScaleBig
    0%
        opacity: 0
        transform: scale(1)

    50%
        opacity: 1
        transform: scale(1.1)

    100%
        opacity: 1
        transform: scale(1)


@keyframes spinAnim
    100%
        transform: rotate(360deg)


.cookies
    position: fixed
    bottom: 20px
    z-index: 999
    width: 100%
    display: flex
    justify-content: center
    +max-width($desktop)
        bottom: 0

    &__wrapper
        display: flex
        justify-content: space-between
        align-items: center
        padding: 15px 32px
        background: $lightbg
        gap: 60px
        width: 50%
        border-radius: 20px
        +max-width($desktop)
            width: 100%
            border-radius: 0
            gap: 24px
            padding: 20px 24px
        +max-width($sm-mobail)
            flex-direction: column
            align-items: flex-start
            gap: 16px
            padding: 10px 15px

    &__text
        font: normal 400 18px/140% $font
        +max-width($tablet)
            font-size: 12px

    &__btn
        padding: 5px 20px
        border-radius: 20px
        color: $white
        background-color: $main-color
        border: 1px solid transparent
        transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease
        text-transform: uppercase

        &:hover
            color: $main-color
            background-color: $white
            border: 1px solid $main-color