.flex
	display: flex


.flexWrap
	&Nowrap
		flex-wrap: nowrap

	&Wrap
		flex-wrap: wrap

	&Reverse
		flex-wrap: wrap-reverse


.flexDirection
	&Column
		flex-direction: column

	&ColumnReverse
		flex-direction: column-reverse

	&Row
		flex-direction: row

	&RowReverse
		flex-direction: row-reverse


.alignItems
	&FlexStart
		align-items: flex-start

	&FlexEnd
		align-items: flex-end

	&Center
		align-items: center

	&Stretch
		align-items: stretch

	&Baseline
		align-items: baseline


.justifyContent
	&FlexStart
		justify-content: flex-start

	&FlexEnd
		justify-content: flex-end

	&Center
		justify-content: center

	&SpaceAround
		justify-content: space-around

	&SpaceBetween
		justify-content: space-between
